
@media screen and (min-width:0px) and (max-width:479px) {
  
  .sidebar {
    transform: translateX(-230px);
    position: fixed;
    top: 80px;
    left: 0;
    height: fit-content;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.886);
}
.sidebar.hidden + .sidebar-toggle-button {
  transform: translateX(-230px);
}
.sidebar.show {
    transform: translateX(0);
}

.sidebar-toggle-button {
  display: block;
  position: fixed;
  top: 100px;
  left: 235px;
  width: 30px;
  z-index: 1000;
  color: rgb(121, 121, 121);
  border: .5px solid rgb(222, 221, 221);
  background-color: rgba(255, 255, 255, 0.886);
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}
.main {
  margin: 0;
  padding: 20px 0 0 0;
}
 .main2{
  margin: 0;
  padding: 20px 0 0 0;
  width: 150%;
}
.navbar{
  width: 120%;
}
.navbar .user-info{
  width: 30%;
}
.navbar .user-info>svg{
 margin-left: 10px;
}
}

@media screen and (min-width:480px) and (max-width:767px) {
  .sidebar {
    transform: translateX(-230px);
    position: fixed;
    top: 80px;
    left: 0;
    height: fit-content;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.886);
}
.sidebar.hidden + .sidebar-toggle-button {
  transform: translateX(-230px);
}
.sidebar.show {
    transform: translateX(0);
}
.navbar{
  width: 100%;
}
.sidebar-toggle-button {
    display: block;
    position: fixed;
    top: 100px;
    left: 235px;
    width: 30px;
    z-index: 1000;
    color: rgb(121, 121, 121);
    border: .5px solid rgb(222, 221, 221);
    background-color: rgba(255, 255, 255, 0.886);
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
}
.main {
  margin: 0;
  padding: 20px 0 0 0;
}
.main2{
  margin: 0;
  padding: 20px 0 0 0;
  width: 120%;
}
.navbar .user-info{
  width: 30%;
}
.navbar .user-info>svg{
  margin-left: 10px;
 }
 
}
@media only screen and (min-width:768px) and (max-width:1260px) {
  .main {
    margin-left: 20px;
    padding: 20px;
    align-self: start;
  }
  .sidebar{
    width:200px ;
  }
   .main2{
    margin-left: 20px;
    align-self: start;
width:70%;
  }
  .sidebar-toggle-button {
    display: none;
    }
    .navbar .user-info{
      gap: 10px;
    }
    .navbar .user-info{
      width:30%;
    }
}
@media only screen and (min-width:1261px) and (max-width:3000px) {
  .main {
    margin-left: 20px;
    padding: 20px;
    align-self: start;
  }
   .main2{
    margin-left: 20px;
    align-self: start;
    width: 90%;
  }
  .sidebar-toggle-button {
    display: none;
    }
    .navbar .user-info{
      width: 15%;
    }
}
.sidebar {
  /* background-color: #fff; */
  width: 250px;
  padding: 20px;
  border: 1px solid rgb(222, 221, 221);
  border-radius: 8px;
  height: fit-content;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
}

.sidebar strong {
  color: #626262;
}

.sidebar a {
  display: block;
  color: #7D7D7D;
  text-decoration: none;
}
.sidebar>a:hover {
background-color: rgba(156, 156, 156, 0.156);
}
/* .sidebar.hidden {
  transform: translateX(-250px);
} */
.sidebar.show {
  transform: translateX(0);
}


.main>p{
  color: #7D7D7D;
}


.header .user-info {
  display: flex;
  align-items: center;
}

.header .user-info img {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.rules>p {
  margin:15px 0 0 20px;
}
.rules p i {
  margin-right: 10px;
}

.btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}