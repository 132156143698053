@media screen and (min-width:0px) and (max-width:479px) {
  .container2 {
    width:80%;
    margin-left:30px ;
  }
  .container3 {
    width:80%;
    margin-left:30px ;
  }
  .chart-container {
    width: 100vw;
  }
}

@media screen and (min-width:480px) and (max-width:767px) {
  .container2 {
    width:80%;
    margin-left:30px ;
  }
  .container3 {
    width:80%;
    margin-left:30px ;
  }
}

@media only screen and (min-width:768px) and (max-width:1260px) {
  .container2 {
    display: flex;
    justify-content: start;
    width:80%;
    margin-left:30px ;
  }
  .container3 {
    display: flex;
    justify-content: space-between;
    width:95%;
    margin-left:30px ;
  }
  .chart-container {
    width: 60vw;
  }
  .chartHead{
    width: 100%;
  }
}

@media only screen and (min-width:1261px) and (max-width:3000px) {
  .container2 {
    display: flex;
    justify-content: start;
    width:100%;
    margin-left:30px ;
  }
  .container3 {
    display: flex;
    justify-content: start;
    width:95%;
    margin-left:30px ;
  }
  .chart-container {
    width: 55vw;
  }
  .chartHead{
    width:70%;
  }
}
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.container1 {
  display: flex;
  justify-content: center;
  width: 100%;
}
/* .container2 {
  width:100%;
  margin-left:30px ;
} */
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 !important;
}
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 !important;
}

.left-side {
  background: white;
  padding: 2rem;
  width: 100%;
  margin: 1rem 0;
}

.logo-container img {
  display: block;
  margin: 0 -60% auto 1rem;
  /* Centered the logo */
}

.content h2 {
  font-size: 3rem;
  text-align: left;
  /* Centered text */
  margin-bottom: 1rem;
  font-weight: 800;
  margin-top: 11%;
}

.welcome-text {
  font-size: 3rem;
  text-align: left;
  /* Centered text */
  margin-bottom: 1rem;
  font-weight: 800;
  margin-top: 11%;
}

.content p {
  text-align: left;
  /* Centered text */
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
  /* Increased spacing */
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  /* Adjusted size */
  font-weight: 600;
  /* Adjusted weight */
}

.form-group input {
  width: 100%;
  /* Ensure full width */
  max-width: none !important;
  /* Disable max-width */
  padding: 0.75rem 1rem;
  /* Adjusted padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.1rem;
  /* Adjusted font size */
  box-sizing: border-box;
  /* Ensure padding and border are included in width */
}

.btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  /* Adjusted font size */
}

.btn:hover {
  background-color: #0056b3;
}

.message {
  text-align: left;
  margin-top: 1rem;
  color: green;
}

.form-footer {
  text-align: center;
  margin-top: 1rem;
  /* Add margin to separate from inputs */
}

.form-footer a {
  color: #007bff;
  text-decoration: none;
}

.form-footer a:hover {
  text-decoration: underline;
}

/* Media Queries for responsiveness */
/* @media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
} */