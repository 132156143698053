body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
  }
  .form-group {
    margin-bottom: 15px;
    
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group .tooltip {
    font-size: 0.9em;
    color: #666;
  }
  
  .checkbox-group input {
    margin-right: 10px;
  }
  
  .form-actions {
    display: flex;
    gap: 1rem;
    width: 60%;
  }
  
  .btn {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .btn.back {
    background-color: #6c757d;
  }
  
  .nav-tabs {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
  }
  
  .nav-tabs a {
    padding: 10px 20px;
    text-decoration: none;
    color: #626262;
    border-bottom: none;
    margin-right: 5px;
    border-radius: 4px 4px 0 0;
  }
  
  .nav-tabs a.active {
    color: #007bff;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: none;
}

  
  .header img {
    height: 50px;
  }
  
  .header .user-info {
    display: flex;
    align-items: center;
  }
  
  .header .user-info span {
    margin-right: 10px;
  }
  
  .header .user-info img {
    height: 20px;
    margin-right: 10px;
  }
  