/* Media Queries for responsiveness */
@media screen and (min-width:0px) and (max-width:479px) {
  .login-right-side {
    display: none;
  }

}

@media screen and (min-width:480px) and (max-width:767px) {
  .login-right-side {
    display: none;
  }
}

@media only screen and (min-width:768px) and (max-width:1260px) {
  .login-container {
    gap: 1rem
  }
}

@media only screen and (min-width:1261px) and (max-width:3000px) {
  .login-container {
    gap: 10rem
  }
}

body.login-page {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.login-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 95vh;
  padding: 1rem;
  width: 100vw;
}

.login-left-side {
  padding: 2rem;
}

.login-right-side img {
  width: 100%;
}


.login-content {
  text-align: left;
  /* Align content to the left */
}

.login-content h2 {
  font-size: 3rem;
  text-align: left;
  margin-bottom: 1rem;
  font-weight: 800;
  margin-top: 11%;
}

.login-welcome-text {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 800;
  margin-top: 11%;
}

.login-content p {
  margin-bottom: 2rem;
  text-align: left;
}

.login-custom-google-btn {
  background-color: #fff;
  color: #4285f4;
  padding: 10px 20px;
  border: 1px solid #4285f4;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 20px;
  /* Add some spacing between the button and form */
  width: 100%;
  /* Adjust width to fit as in the image */
  height: 50px;
  /* Adjust height to fit as in the image */
}

.login-custom-google-btn:hover {
  background-color: #357ae8;
  color: #fff;
}

.login-google-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.login-separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
}

.login-separator-line {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background-color: #ccc;
}

.login-separator-text {
  padding: 0 1rem;
  color: #999;
}

.login-form-group {
  margin-bottom: 1.5rem;
  /* Increased spacing */
}

.login-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  /* Adjusted size */
  font-weight: 600;
  /* Adjusted weight */
}

.login-form-group input {
  width: 100% !important;
  /* Ensure full width */
  max-width: none !important;
  /* Disable max-width */
  padding: 0.75rem 1rem;
  /* Adjusted padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.1rem;
  /* Adjusted font size */
  box-sizing: border-box;
  /* Ensure padding and border are included in width */
}

.login-password-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.login-password-container label {
  margin: 0;
}

.login-password-container a {
  color: #007bff;
  text-decoration: none;
}

.login-password-container a:hover {
  text-decoration: underline;
}

.login-form-footer {
  text-align: right;
  margin-bottom: 1rem;
  /* Added margin to separate from inputs */
}

.login-form-footer a {
  color: #007bff;
  text-decoration: none;
}

.login-form-footer a:hover {
  text-decoration: underline;
}

.login-forgot-password {
  text-align: right;
  margin-bottom: 1rem;
}

.login-remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  /* Increased spacing */
}

.login-remember-me input {
  margin-right: 0.5rem;
}

.login-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  /* Adjusted font size */
}

.login-btn:hover {
  background-color: #0056b3;
}

.login-error-message {
  color: red;
  text-align: center;
  margin-bottom: 1rem;
}

.login-success-message {
  color: green;
  text-align: center;
  margin-bottom: 1rem;
}

.login-signup-footer {
  text-align: center;
  margin-top: 1rem;
  /* Add margin to separate from inputs */
}

.login-signup-footer a {
  color: #007bff;
  text-decoration: none;
}

.login-signup-footer a:hover {
  text-decoration: underline;
}