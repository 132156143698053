@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Media Queries for responsiveness */
@media screen and (min-width:0px) and (max-width:479px) {
 
.left-side {
  width: 85%;
  margin: 0 auto;
}
.register-container {
  width: 160%;
}

}

@media screen and (min-width:480px) and (max-width:767px) {
  .left-side {
    width: 75%;
    margin: 0 auto;
  }
  .register-container {
    width: 130%;
  }
}

@media only screen and (min-width:768px) and (max-width:1260px) {
  .left-side {
    width: 40%;
  }
  .register-container {
    width: 100%;
  }
}

@media only screen and (min-width:1261px) and (max-width:3000px) {
  .left-side {
    width: 25%;
  }
  .register-container {
    width: 100%;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

.register-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100vh;
  padding: 20px;
  margin: 0 auto;
}

.left-side {
  padding: 20px;
}

.logo {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.logo img {
  height: 50px;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
}

.custom-google-btn {
  background-color: #fff;
  color: black;
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin:10px 0;
  width: 100%;
  height: 50px;
}

.custom-google-btn:hover {
  background-color: #357ae8;
  color: #fff;
}

.google-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: .5rem 0;
  width: 100%;
}

.separator-line {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background-color: gray;
}

.separator-text {
  padding: 0 1rem;
  color: #999;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
  position: relative; /* Add relative positioning for form group */
}

.form-group input, .form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  color: black;
}
.form-group input::placeholder{
  color: #c2c2c2;

}
textarea{

  border: 1px solid #adadad;
}
textarea::placeholder{
  color: #adadad;
}
.form-group label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input {
  width: 5% !important;
  margin-right: 10px;
}

.btn {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  font-size: 1em;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
}

.btn:hover {
  background-color: #0056b3;
}

.form-footer {
  text-align: left;
  margin-top: 10px;
}

.form-footer a {
  color: #007bff;
  text-decoration: none;
}

.form-footer a:hover {
  text-decoration: underline;
}

.messages {
  margin-bottom: 15px;
}

.error-message {
  color: red;
  background: #ffe6e6;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
}

.success-message {
  color: green;
  background: #e6ffe6;
  padding: 10px;
  border: 1px solid green;
  border-radius: 4px;
}

.tooltip-container {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 15%; /* Position above the input field */
  right: 0;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  color: #007bff;
  margin:0 5px ;
}

.tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #f9f9f9;
  color: #666; /* Darker text color */
  font-size: 0.8rem;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 105%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-style: normal;
}

.tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #ccc transparent transparent;
}

.tooltiptext::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #f9f9f9 transparent transparent;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Media Queries for responsiveness */
/* @media (max-width: 600px) {
 
  .custom-google-btn {
    font-size: 14px;
    height: 45px;
  }

  .btn {
    font-size: 14px;
    padding: 8px;
  }

  .tooltip {
    font-size: 1.2rem;
  }

  .tooltiptext {
    left: 90%;
  }
} */
